<template>
  <div v-if="isReady" class="checkout">
    <block
      v-if="isComplete || !isCartEmpty"
      class="checkout-main width-expand relative">
      <div
        class="order-area flex flex-stretch gap-max flex-between flex-break-center width-expand relative">
        <card
          v-if="!isComplete && !$store.getters['user/isLoggedIn']"
          title="user.requireAuthComponent.title"
          class="margin-max-top">
          <require-auth-component
            redirect="store-checkout-standard"
            :show-explain-text="true">
          </require-auth-component>
        </card>

        <div
          v-if="!isComplete && $store.getters['user/isLoggedIn']"
          class="order-forms width-expand flex-column gap-xl">
          <component
            :is="stepComponent"
            v-for="(stepComponent, stepName) in stepComponents"
            :key="stepName"
            :cart="cart"
            :step-data="stepData[stepName]"
            :order-data="orderData"></component>

          <checkout-step title="ecommerce.checkout.payment.stepTitle">
            <template #default>
              <div
                class="padding-m-horizontal padding-l-vertical bg-bg relative"
                style="min-height: 80px">
                <payment
                  v-if="readyToPay && cart"
                  ref="payment-component"
                  class="payment"
                  :offline-payment-button-size="'l'"
                  :offline-payment-button-icon="'check'"
                  :gateway="orderData.paymentMethod"
                  :amount="currentQuote.grandTotal"
                  :currency="'USD'"
                  @payment:created="reportPaymentCreated"
                  @payment:completed="handlePaymentCompleted"
                  @payment:failed="handlePaymentFailed">
                </payment>

                <spinner
                  v-else
                  :overlay="'absolute'"
                  text="ecommerce.checkout.payment.finishOtherStepsExplain"
                  :size="2" />
              </div>
            </template>
          </checkout-step>
        </div>

        <div
          v-show="isComplete"
          class="animate__fadeIn order-forms width-expand flex-column gap-xl">
          <card
            v-if="result.isSuccessful"
            icon="check"
            theme="success"
            title="ecommerce.checkout.complete.successTitle">
            <div v-if="result.isSuccessful" class="success"></div>
            <h6>
              {{ translateUcFirst("ecommerce.checkout.complete.successExplain") }}
            </h6>

            <template #footer>
              <p>
                {{ translateUcFirst("ecommerce.checkout.complete.resultDataExplain") }}
              </p>
              <div class="max-width-300">
                <div
                  v-tooltip="{
                    content: 'ecommerce.checkout.complete.orderIdTooltip',
                  }"
                  class="flex flex-between">
                  <icon-text icon="cart-basket"
                    >{{
                      translateUcFirst("ecommerce.checkout.complete.orderId")
                    }}: </icon-text
                  ><strong>{{ order?.id ? order.id : "-" }}</strong>
                </div>
                <div
                  v-tooltip="{
                    content: 'ecommerce.checkout.complete.paymentIdTooltip',
                  }"
                  class="flex flex-between">
                  <icon-text icon="credit-cards"
                    >{{
                      translateUcFirst("ecommerce.checkout.complete.paymentId")
                    }}: </icon-text
                  ><strong>{{ payment?.id ? payment?.id : "-" }}</strong>
                </div>
              </div>
            </template>
          </card>

          <card
            v-if="!result.isSuccessful"
            icon="x"
            theme="error"
            title="ecommerce.checkout.complete.errorTitle">
            <div v-if="result.errors.paymentAssignment">
              <p>
                {{
                  translateUcFirst("ecommerce.checkout.complete.paymentAssignmentError1")
                }}
              </p>
              <p>
                {{
                  translateUcFirst("ecommerce.checkout.complete.paymentAssignmentError2")
                }}
              </p>
            </div>
            <div v-if="result.errors.orderCreation">
              <p>
                {{ translateUcFirst("ecommerce.checkout.complete.orderCreationError1") }}
              </p>
              <p>
                {{ translateUcFirst("ecommerce.checkout.complete.orderCreationError2") }}
              </p>
            </div>

            <template #footer>
              <p>
                {{
                  translateUcFirst("ecommerce.checkout.complete.resultDataErrorExplain")
                }}
              </p>

              <div class="max-width-300">
                <div
                  v-tooltip="{
                    content: 'ecommerce.checkout.complete.orderIdTooltip',
                  }"
                  class="flex flex-between">
                  <icon-text icon="cart-basket"
                    >{{
                      translateUcFirst("ecommerce.checkout.complete.orderId")
                    }}: </icon-text
                  ><strong>{{ order?.id ? order.id : "-" }}</strong>
                </div>
                <div
                  v-tooltip="{
                    content: 'ecommerce.checkout.complete.paymentIdTooltip',
                  }"
                  class="flex flex-between">
                  <icon-text icon="credit-cards"
                    >{{
                      translateUcFirst("ecommerce.checkout.complete.paymentId")
                    }}: </icon-text
                  ><strong>{{ payment?.id ? payment?.id : "-" }}</strong>
                </div>
              </div>
            </template>
          </card>
        </div>

        <div class="cart-container bg-bg" style="">
          <div class="cart-expander m:hidden">
            <block
              v-if="cart"
              v-show="!isCartExpanded"
              class="container flex gap-m flex-center animate__fadeIn animate--fast"
              @click="isCartExpanded = true">
              <span v-if="currentQuote"
                >{{ translateUcFirst("ecommerce.checkout.cart.reviewCartCta") }} ({{
                  translateNumber(currentQuote.grandTotal, "currency", {
                    currency: currentQuote.currency,
                  })
                }})</span
              >
              <icon icon="chevron-down"></icon>
            </block>
            <block
              v-show="isCartExpanded"
              class="container flex gap-m flex-center animate__fadeIn animate--fast"
              @click="isCartExpanded = false">
              <span>{{ translateUcFirst("ecommerce.checkout.cart.closeCartCta") }}</span>
              <icon icon="chevron-up"></icon>
            </block>
          </div>
          <div
            class="cart-container-inner animate__fadeIn animate--fast bg-bg"
            style=""
            :class="{ expanded: isCartExpanded }">
            <slot name="cart" v-bind="{cart,currentQuote,isCartExpanded}">
              <cart
                v-if="cart && currentQuote"
                class="cart"
                :read-only="true"
                :cart="cart"
                :show-action-bar="false">

                <template #cart-item-extra="{ item, product }">
                  <div class="text-s flex-column width-expand gap-xs">
                    <div class="div custom-pricing text-bold">
                    <span class="bold">{{
                        translateNumber(item.grandTotal, "currency", {
                          currency: item.cartItem.Product.currency,
                        })
                      }}</span>
                    </div>
                    <div class="margin-m-top">
                      <span>{{ product.Vendor.name }}</span>
                    </div>
                    <div v-if="item?.cartItem?.productConfig?.timedService?.startDateTime">
                    <span>{{
                        translateDate(
                          item?.cartItem?.productConfig?.timedService?.startDateTime,
                          "dateWithTime"
                        )
                      }}</span>
                    </div>
                  </div>
                </template>
                <template #cart-item-controls="{ item, product }">
                  <span class="hidden"></span>
                </template>
              </cart>
            </slot>

          </div>
        </div>
      </div>
      <div
        class="margin-xl-bottom container container-m flex flex-center">
        <icon-text
          icon="lock"
          :icon-size="2"
          icon-align="top"
          gap="m"
          class="text-s margin-l-top">
          {{ translateUcFirst("ecommerce.checkout.safePurchaseExplain") }}
        </icon-text>
      </div>
    </block>

    <block
      v-if="!isComplete && cart?.isEmpty"
      class="checkout-main width-expand relative margin-max-bottom">
      <card title="ecommerce.checkout.noItemsForCheckout.title" class="margin-max-top">
        <p>
          {{ tu("ecommerce.checkout.noItemsForCheckout.explain") }}
        </p>
        <div class="button-container margin-2xl-top">
          <form-button
            text="ecommerce.checkout.noItemsForCheckout.ctaText"
            icon="home"
            @click="$router.push('/')"></form-button>
        </div>
      </card>
    </block>
  </div>

  <div v-else class="checkout">
    <div class="flex flex-middle flex-center" style="min-height: 70vh; min-height: 70lvh;">
      <spinner class="" :size="5" text="ecommerce.checkout.checkoutLoadingText"></spinner>
    </div>
  </div>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
import useCarts from "@/client/extensions/composition/useCarts.js";
import Cart from "@/client/components/ecommerce/Cart.vue";
import Payment from "@/client/components/payment/Payment.vue";
import CheckoutStep from "@/client/components/ecommerce/checkout/CheckoutStep";
import CreateUserAddressModal from "@/client/components/ecommerce/address/CreateUserAddressModal";
import FormInput from "@/client/components/base/frameworks/we/form/controls/FormInput";
import asyncComponentFactory from "@/client/extensions/modules/asyncComponentFactory";
import requireAuthComponent from "@/client/components/etc/RequireAuth.vue";
import StandardShippingAndAddress from "@/client/components/ecommerce/checkout/steps/StandardShippingAndAddress";

export default {
  components: {
    FormInput,
    Cart,
    Payment,
    CheckoutStep,
    CreateUserAddressModal,
    StandardShippingAndAddress,
    requireAuthComponent,
  },
  props: {
    cartUuid: {
      type: [String],
      default: undefined,
    },
    steps: {
      type: Object,
      default: {
        keysDontMatter: {
          component: "StandardShippingAndAddress",
        },
        internalNamesBasedOnComponentProperty: {
          component: "StandardPaymentMethodSelect",
        },
      },
    },
  },
  setup(props) {
    const { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations();
    let carts = useCarts(props);

    return { asyncOps, asyncOpsReady, asyncStatus, carts };
  },
  data() {
    let stepComponents = {};
    let stepData = {};
    for (const [key, conf] of Object.entries(this.steps)) {
      let target = conf.component;
      let stepName = utilities.lcFirst(target);
      stepData[stepName] = {
        isComplete: false,
      };
      let componentTarget = utilities.ucFirst(target);
      stepComponents[stepName] = asyncComponentFactory(
        `components/ecommerce/checkout/steps/${componentTarget}.vue`,
        true
      );
    }

    return {
      cart: false,
      isReady: false,
      stepData,
      orderData: {
        shippingMethodType: null, // "delivery",
        paymentMethod: null, //"offline",
        addressId: null, //3,
      },
      result: {
        isSuccessful: true,
        errors: {
          payment: false,
          paymentAssignment: false,
          orderCreation: false,
        },
      },
      isCartExpanded: false,
      isComplete: false,
      order: false,
      readyToPay: false,
      payment: null,
      stepComponents: stepComponents,
      asyncData: {},
    };
  },
  computed: {
    isCartEmpty() {
      return this.cart && !this.cart.isEmpty;
    },
    currentQuote() {
      if (!this.cart) {
        return false;
      }

      if (this.order) {
        return this.order.Quote;
      }

      return this.cart.quote;
    },
  },
  watch: {
    stepData: {
      handler(newVal) {
        this.handleStepDataUpdated(newVal);
      },
      deep: true,
    },
  },
  async mounted() {
    this.cart = await this.carts.getCart(this.cartUuid);
    this.isReady = true;
  },
  methods: {
    async handleStepDataUpdated(newVal) {
      let isComplete = true;
      await utilities.wait(10);
      Object.values(this.stepData).forEach((stepData) => {
        if (!stepData.isComplete) {
          isComplete = false;
          return false;
        }
      });

      if (!isComplete) {
        return;
      }

      this.$s.ui.globalSpinner.show("ecommerce.checkout.orderPreflightLoading");
      let orderPreflightResult = await this.doOrderPreflight();
      if (!orderPreflightResult) {
        return this.$s.ui.notification(
          "ecommerce.checkout.orderPreflightFailed",
          "error"
        );
      }
      this.readyToPay = isComplete;
      this.$s.ui.globalSpinner.hide();
    },
    async clearCart() {
      await this.cart.empty();
      await this.cart.refresh();
      this.cart = await this.carts.getCart();
    },
    async updateCart(item) {},
    async getDataForCreateOrder() {
      let profile = this.$store.getters["user/profile"];
      const orderData = {
        UserId: profile?.id,
        OwnerId: profile?.id,
        CartUuid: this.cart.uuid,
        buyer: profile ? profile : {},
        shipping: {
          method: this.orderData.shippingMethodType,
          address: { todo: "address module states" },
        },
        addressId: this.orderData.addressId,
      };

      return orderData;
    },
    async doOrderPreflight() {
      let profile = this.$store.getters["user/profile"];
      const orderData = await this.getDataForCreateOrder();
      orderData.preflight = true;

      const result = await this.asyncOps.asyncCall("ecommerce/order", orderData, {
        method: "post",
      });

      if (result.hasError) {
        return false;
      }

      return result.data.item;
    },
    async createOrder(preflight = false) {
      let profile = this.$store.getters["user/profile"];
      const orderData = await this.getDataForCreateOrder();

      this.$s.ui.globalSpinner.show();

      const result = await this.asyncOps.asyncCall("ecommerce/order", orderData, {
        method: "post",
      });

      if (result.hasError) {
        return false;
      }

      this.$s.ui.globalSpinner.hide();

      // we need to asign the quote from the order to the cart component, and clear the cart
      this.$nextTick(() => {
        this.cart.empty();
      });
      return result.data.item;
    },
    async handlePaymentCompleted({ payment }) {
      this.payment = payment;
      let order = await this.createOrder();
      if (!order) {
        this.result.isSuccessful = false;
        this.result.errors.orderCreation = true;
        this.isComplete = true;
        return false;
      }

      this.order = order;
      let assignResult = await this.asyncOps.asyncCall(
        `payment/${payment.uuid}/assign`,
        {
          payableType: "order",
          payableId: order.id,
        },
        { method: "patch" }
      );

      if (assignResult.hasError) {
        this.result.isSuccessful = false;
        this.result.errors.paymentAssignment = true;
        this.isComplete = true;
        this.$s.ui.globalSpinner.hide();
        return false;
      }
      this.result.isSuccessful = true;
      this.isComplete = true;
    },
    handlePaymentFailed(eventData) {},
    reportPaymentCreated(eventData) {},
  },
};
</script>

<style scoped lang="scss">
.checkout {
  // TODO: fix this to framework color
  background-color: #faf9f6; // var(--bg-4)
  padding-bottom: var(--margin-l);
}
.offline-checkout-explain {
  width: 100%;
  flex: 100%;
  margin-inline: auto;

  @media (max-width: 1024px) {
    max-width: 700px;
    height: auto;
  }
}
.order-area {
  width: 100%;
  flex: 100%;
  margin-inline: auto;
  max-width: var(--container-l);
  padding: 0 var(--container-gutter) 0 var(--container-gutter);
  margin-bottom: var(--margin-xl);

  @media (max-width: 1024px) {
    max-width: 700px;
    height: auto;
    margin: 0;
    padding-top: 0;
  }
}

.order-forms {
  padding-inline: var(--margin-l);

  padding-top: var(--margin-max);

  @media (max-width: 1024px) {
    padding-inline: 0;
  }

  .checkout-step {
    margin-bottom: var(--margin-xl);
    h4 {
      margin-bottom: var(--margin-m);
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
.cart-container {
  max-width: 400px;
  position: relative;
  padding-top: var(--margin-max);
  padding-inline: var(--margin-l);
  padding-bottom: var(--margin-l);

  @media (max-width: 1024px) {
    height: auto;
    max-width: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 0;
  }

  .cart-expander {
    background-color: var(--c-gray-5);
    border-bottom: 1px solid var(--c-gray-2);
    box-shadow: var(--box-shadow-2);
    color: var(--c-bg);
    padding-block: var(--margin-s);
    cursor: pointer;
  }

  .cart-container-inner {
    width: 360px;
    position: sticky;
    top: 120px; // TODO: this depends on  header height

    @media (max-width: 1024px) {
      position: relative;
      top: 0;
      max-width: 100%;
      min-width: 0;
      width: 100%;
      display: none;
      padding: var(--margin-m);
      background: var(--c-gray);
      z-index: var(--z-2);
      max-height: 60vh;
      overflow-y: scroll;

      &.expanded {
        display: block;
      }
    }
  }

  .cart {
    @media (max-width: 1024px) {
      background-color: var(--c-bg);
    }
  }
}

.checkout-step-subtitle {
  font-size: var(--text-l);
  font-weight: normal;
}
</style>
