<template>
  <modal
    ref="modal"
    v-bind="$props"
    :show-footer="false"
    :show="opened"
    :open="opened"
    title="ecommerce.address.createAddressTitle">
    <template #default>
      <base-form
        ref="addressForm"
        v-model="newAddressData"
        action="ecommerce/address"
        method="post"
        :config="{ buttons: {} }"
        form-path="entity/address/create"
        @form:submit-success="addressCreatedHandler"></base-form>
    </template>
    <template #footer="{ modal }">
      <div class="flex flex-end gap-m">
        <form-button
          icon="x"
          theme="gray-5"
          text="core.cancel"
          @click="modal.close()"></form-button>
        <form-button icon="check" text="ecommerce.address.addAddressCta" @click="submitForm"></form-button>
      </div>
    </template>
  </modal>
</template>

<script>
export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    showNotifications: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["create-address:success", "create-address:abort", "create-address:aborted"],
  data: function () {
    return {
      newAddressData: {},
    };
  },
  watch: {
    opened: {
      handler(newVal) {
        try {
          if (newVal) {
            this.$refs["modal"].show();
          } else {
            this.$refs["modal"].hide();
          }
        } catch (e) {}
      },
      immediate: true,
    },
  },
  methods: {
    addressCreatedHandler(eventData, result) {
      this.$emit("create-address:success", eventData);
      if (this.showNotifications) {
        this.$s.ui.notification("core.successGeneric", "success");
      }

      this.newAddressData = {};
      return true;
    },
    submitForm() {
      this.$refs["addressForm"].submit();
    },
  },
};
</script>

<style scoped lang="scss"></style>
